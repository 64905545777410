<template>
  <div class="appbar">
    <div class="appbar__container">
      <template v-if="isAuthenticated">
        <div class="appbar__actions">
          <template v-for="(action, index) in actions">
            <router-link
              :key="index"
              tag="button"
              class="appbar__action"
              :to="action.path"
            >
              {{ action.name }}
            </router-link>
          </template>
        </div>
        <router-link
          class="appbar__profile"
          :to="{ name: ROUTE_NAMES.PERSONAL }"
        >
          <div class="appbar__profile-username">您好，{{ user.username }}</div>
          <div class="appbar__profile-balance">
            <img src="@/assets/images/appbar/money.png" alt="" />
            <span v-money-format.no-symbol="user.main_balance" />
          </div>
        </router-link>
        <button type="button" class="appbar__logout" @click="logout">
          登出
        </button>
      </template>
      <template v-else>
        <button type="button" class="appbar__login" @click="login">
          登入/註冊
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ROUTE_NAMES } from "@/router";
import AuthService from "@/service/AuthService";

export default {
  name: "AppBar",
  data() {
    return {
      ROUTE_NAMES,
      actions: [
        {
          name: "儲值",
          path: { name: ROUTE_NAMES.PERSONAL_WALLET_RECHARGE },
        },
        {
          name: "託售",
          path: { name: ROUTE_NAMES.PERSONAL_WALLET_WITHDRAW },
        },
        {
          name: "轉點",
          path: { name: ROUTE_NAMES.PERSONAL_WALLET_TRANSFER },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      isAuthenticated: "user/isAuthenticated",
    }),
  },
  methods: {
    login() {
      this.$router.push({ name: ROUTE_NAMES.LOGIN });
    },
    logout() {
      AuthService.getInstance().logout();
      this.$router.push({ name: ROUTE_NAMES.LOGIN });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable";

@mixin button() {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  padding: 1px 8px;
}

.appbar {
  background: $appbar-background;

  &__container {
    align-items: center;
    display: flex;
    justify-content: end;
    margin: auto;
    padding: 6px 10px;
    width: $--container-width;
  }

  &__actions {
    display: flex;
    gap: 8px;
  }

  &__action {
    @include button();

    border-color: $appbar-action-border;
    color: $appbar-action-text;
    transition: color 0.5s;

    &:hover {
      border: 1px solid $appbar-action-border-active;
      color: $appbar-action-text-active;
    }
  }

  &__profile {
    align-items: center;
    display: flex;
    font-size: 12px;
    margin: 0 12px;

    &-username {
      color: $--color-white;
      cursor: pointer;
      margin-right: 8px;
    }

    &-balance {
      align-items: center;
      color: $--color-white;
      cursor: pointer;
      display: flex;
      font-family: $--font-price-family;

      img {
        height: 16px;
        margin-right: 4px;
        width: 16px;
      }
    }
  }

  &__login,
  &__logout {
    @include button();

    background-color: $appbar-logout-background;
    border-color: $appbar-logout-border;
    color: $appbar-logout-text;
    transition: background 0.5s;

    &:hover {
      background-color: $appbar-logout-background-hover;
      border-color: $appbar-logout-border-hover;
    }
  }
}
</style>
