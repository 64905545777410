import axios from "@/plugins/axios";

export default {
  upload(data) {
    return axios({
      url: "/file/upload",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data,
    });
  },
};
