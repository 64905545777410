import Vue from "vue";
import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";

localize("zh_TW", {
  code: "zh_TW",
  messages: {
    alpha: "{_field_} 應僅包含英文字母。",
    alpha_dash: "{_field_} 可以包含英文字母、數字、斜線及底線。",
    alpha_num: "{_field_} 應僅包含英文字母和數字。",
    alpha_spaces: "{_field_} 只能包含英文字母和空格。",
    between: "{_field_} 的值應介於 {min} 至 {max} 之間。",
    confirmed: "{_field_} 的確認輸入不符合。請再次確認。",
    digits: "{_field_} 應為 {length} 位數字。例如: 123456。",
    dimensions: "{_field_} 的圖片尺寸不正確，需要 {width} x {height} 像素。",
    email: "請確保 {_field_} 是有效的電子郵件地址。",
    excluded: "{_field_} 選擇的選項無效，請重新選擇。",
    ext: "{_field_} 應為有效的檔案格式。",
    image: "{_field_} 應為圖片檔案。",
    oneOf: "{_field_} 選擇的選項無效，請重新選擇。",
    integer: "{_field_} 必須是整數。",
    length: "{_field_} 的長度應為 {length} 個字元。",
    max: "{_field_} 的字元長度不應超過 {length}。",
    max_value: "{_field_} 不能大於 {max}。",
    mimes: "{_field_} 應為有效的檔案類型。",
    min: "{_field_} 的字元長度不應少於 {length}。",
    min_value: "{_field_} 不能小於 {min}。",
    numeric: "{_field_} 應為數字。",
    regex: "{_field_} 的格式不正確，請按照指定格式輸入。",
    required: "{_field_} 是必填項目。",
    required_if: "{_field_} 是必填項目。",
    size: "{_field_} 的檔案大小應小於 {size}KB。",
    double: "{_field_} 應為有效的小數。例如: 123.45。",
    phone: "請輸入有效的 {_field_}。例如: 0912345678。",
    password_strength: "{_field_} 應包含英文字母、數字，且長度為6-20個字元。",
    different: "{_field_} 與 舊密碼 不得相同。",
  },
});

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend("phone", {
  validate: (value) => {
    const reg = /^09[0-9]{8}$/;

    return reg.test(value);
  },
});

extend("password_strength", {
  validate: (value) => {
    const reg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,20}$/;

    return reg.test(value);
  },
});

extend("different", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
