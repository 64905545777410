import axios from "@/plugins/axios";

export default {
  menu() {
    return axios({
      url: "/game/menu",
      method: "GET",
    });
  },
  list(params) {
    return axios({
      url: "/game/list",
      method: "GET",
      params,
    });
  },
  login(data) {
    return axios({
      url: "/game/login",
      method: "POST",
      data,
    });
  },
};
