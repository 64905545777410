<template>
  <div class="game-dropdown">
    <template v-for="(item, index) in items">
      <div :key="index" class="game-dropdown__item" @click="login(item)">
        <img
          class="game-dropdown__logo"
          :src="item.navigation_image.web"
          alt=""
        />
        <div class="game-dropdown__name">{{ item.name }}</div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "GameDropdown",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    login(game) {
      this.$emit("login", game);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable";

.game-dropdown {
  align-items: center;
  background: $game-dropdown-background;
  border-bottom: 1px solid $--border-color-base;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
  display: flex;
  gap: 60px;
  justify-content: center;
  left: 0;
  padding: 20px 0;
  position: absolute;
  right: 0;
  top: 110px;
  z-index: 1900;

  &__item {
    align-items: center;
    display: flex;
    flex-direction: column;

    &:hover .game-dropdown__logo {
      transform: scale(1.1);
      transition: transform 0.3s;
    }
  }

  &__logo {
    height: 180px;
    width: 180px;
  }

  &__name {
    font-size: 20px;
    font-weight: bold;
    margin-top: 12px;
  }
}
</style>
