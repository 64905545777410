import axios from "@/plugins/axios";

export default {
  bootstrap() {
    return axios({
      url: "/common/bootstrap",
      method: "GET",
    });
  },
  sendCaptcha(data) {
    return axios(
      {
        url: "/common/send-captcha",
        method: "POST",
        data,
      },
      { showLoading: false }
    );
  },
  paymentChannels() {
    return axios({
      url: "/common/payment-channels",
      method: "GET",
    });
  },
};
