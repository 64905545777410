import Vue from "vue";
import VueRouter from "vue-router";
import maintenance from "./guards/maintenance";
import authenticate from "./guards/authenticate";
import AuthLayout from "@/layouts/auth-layout.vue";
import DefaultLayout from "@/layouts/default-layout.vue";
import PersonalLayout from "@/layouts/personal-layout.vue";
import verified from "@/router/guards/verified";

Vue.use(VueRouter);

export const ROUTE_NAMES = {
  HOME: "Home",
  LOGIN: "Login",
  REGISTER: "Register",
  MAINTENANCE: "Maintenance",
  ACTIVITY: "Activity",
  ACTIVITY_DETAIL: "ActivityDetail",
  GAME_SLOT: "GameSlot",
  PERSONAL: "Personal",
  PERSONAL_INFORMATION: "PersonalInformation",
  PERSONAL_BANK_ACCOUNT: "PersonalBankAccount",
  PERSONAL_BANK_ACCOUNT_CREATE: "PersonalBankAccountCreate",
  PERSONAL_BANK_ACCOUNT_UPDATE: "PersonalBankAccountUpdate",
  PERSONAL_BET_RECORD: "PersonalBetRecord",
  PERSONAL_TRANSACTION_RECORD: "PersonalTransactionRecord",
  PERSONAL_CHANGE_PASSWORD: "PersonalChangePassword",
  PERSONAL_NOTIFICATION: "PersonalNotification",
  PERSONAL_WALLET_TRANSFER: "PersonalWalletTransfer",
  PERSONAL_WALLET_RECHARGE: "PersonalWalletRecharge",
  PERSONAL_WALLET_WITHDRAW: "PersonalWalletWithdraw",
};

const routes = [
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "/",
        name: ROUTE_NAMES.HOME,
        component: () => import("@/views/index.vue"),
      },
      {
        path: "/game/slot",
        name: ROUTE_NAMES.GAME_SLOT,
        component: () => import("@/views/game/slot.vue"),
      },
      {
        path: "/activity",
        name: ROUTE_NAMES.ACTIVITY,
        component: () => import("@/views/activity/index.vue"),
      },
      {
        path: "/activity/:activityId(\\d+)",
        name: ROUTE_NAMES.ACTIVITY_DETAIL,
        component: () => import("@/views/activity/detail/index.vue"),
      },
      {
        path: "/personal",
        component: PersonalLayout,
        name: ROUTE_NAMES.PERSONAL,
        meta: { requiresAuth: true },
        redirect: { name: ROUTE_NAMES.PERSONAL_INFORMATION },
        children: [
          {
            path: "/personal/information",
            name: ROUTE_NAMES.PERSONAL_INFORMATION,
            component: () => import("@/views/personal/information.vue"),
          },
          {
            path: "/personal/bank-account",
            name: ROUTE_NAMES.PERSONAL_BANK_ACCOUNT,
            component: () => import("@/views/personal/bank-account/index.vue"),
          },
          {
            path: "/personal/bank-account/create",
            name: ROUTE_NAMES.PERSONAL_BANK_ACCOUNT_CREATE,
            meta: { requiresVerified: true },
            component: () => import("@/views/personal/bank-account/create.vue"),
          },
          {
            path: "/personal/bank-account/update",
            name: ROUTE_NAMES.PERSONAL_BANK_ACCOUNT_UPDATE,
            meta: { requiresVerified: true },
            component: () => import("@/views/personal/bank-account/update.vue"),
          },
          {
            path: "/personal/change-password",
            name: ROUTE_NAMES.PERSONAL_CHANGE_PASSWORD,
            component: () => import("@/views/personal/change-password.vue"),
          },
          {
            path: "/personal/bet-record",
            name: ROUTE_NAMES.PERSONAL_BET_RECORD,
            component: () => import("@/views/personal/bet-record.vue"),
          },
          {
            path: "/personal/transaction-record",
            name: ROUTE_NAMES.PERSONAL_TRANSACTION_RECORD,
            component: () => import("@/views/personal/transaction-record.vue"),
          },
          {
            path: "/personal/notification",
            name: ROUTE_NAMES.PERSONAL_NOTIFICATION,
            component: () => import("@/views/personal/notification.vue"),
          },
          {
            path: "/wallet/recharge",
            name: ROUTE_NAMES.PERSONAL_WALLET_RECHARGE,
            component: () => import("@/views/personal/wallet/recharge.vue"),
          },
          {
            path: "/wallet/withdraw",
            name: ROUTE_NAMES.PERSONAL_WALLET_WITHDRAW,
            component: () => import("@/views/personal/wallet/withdraw.vue"),
          },
          {
            path: "/wallet/transfer",
            name: ROUTE_NAMES.PERSONAL_WALLET_TRANSFER,
            component: () => import("@/views/personal/wallet/transfer.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: AuthLayout,
    children: [
      {
        path: "login",
        name: ROUTE_NAMES.LOGIN,
        component: () => import("@/views/login.vue"),
      },
      {
        path: "register",
        name: ROUTE_NAMES.REGISTER,
        component: () => import("@/views/register.vue"),
      },
    ],
  },
  {
    path: "/maintenance",
    name: ROUTE_NAMES.MAINTENANCE,
    component: () => import("@/views/maintenance.vue"),
  },
  {
    path: "*",
    redirect: { name: ROUTE_NAMES.HOME },
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(maintenance);
router.beforeEach(authenticate);
router.beforeEach(verified);

export default router;
