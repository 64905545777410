import store from "@/store";
import { ROUTE_NAMES } from "@/router";
import AuthService from "@/service/AuthService";

export default async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = store.getters["user/isAuthenticated"];

  if (isAuthenticated) {
    try {
      await AuthService.getInstance().fetchProfile();
    } catch (error) {
      await AuthService.getInstance().logout();
    }
  }

  if (requiresAuth && !isAuthenticated) {
    return next({
      name: ROUTE_NAMES.LOGIN,
      query: { redirectTo: to.fullPath },
    });
  }

  return next();
};
