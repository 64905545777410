const initialState = () => ({
  site: {
    name: "",
    logo: "",
    support_url: "",
    maintenance_mode: true,
  },
});

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SET_SITE(state, site) {
      state.site = { ...site };
    },
  },
  getters: {
    site: (state) => state.site,
    supportUrl: (state, getters, rootState) => {
      let user = rootState.user?.user;
      let username = user?.username;
      let realname = user?.realname;

      if (!username) {
        username = "";
      }

      if (!realname) {
        realname = "";
      }

      return state.site.support_url
        .replace(":username", username)
        .replace(":realname", realname);
    },
    maintenanceMode: (state) => state.site.maintenance_mode,
  },
};
