import axios from "@/plugins/axios";

export default {
  list() {
    return axios({
      url: "/wallet/list",
      method: "GET",
    });
  },
  recover() {
    return axios({
      url: "/wallet/recover",
      method: "POST",
    });
  },
  transfer(data) {
    return axios({
      url: "/wallet/transfer",
      method: "POST",
      data,
    });
  },
  recharge(data) {
    return axios({
      url: "/wallet/recharge",
      method: "POST",
      data,
    });
  },
  withdraw(data) {
    return axios({
      url: "/wallet/withdraw",
      method: "POST",
      data,
    });
  },
};
