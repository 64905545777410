import axios from "@/plugins/axios";

export default {
  list(params) {
    return axios({
      url: "/bank/list",
      method: "GET",
      params,
    });
  },
};
