<template>
  <div :class="$style['game-providers']">
    <template v-for="(provider, index) in providers">
      <div :key="index" :class="$style['game-provider']">
        <img :src="provider.icon" alt="" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "GameProviders",
  data() {
    return {
      providers: [
        {
          icon: require("@/assets/images/game-providers/ab.png"),
        },
        {
          icon: require("@/assets/images/game-providers/bng.png"),
        },
        {
          icon: require("@/assets/images/game-providers/sp.png"),
        },
        {
          icon: require("@/assets/images/game-providers/dg.png"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" module>
@import "@/assets/scss/variable";

.game-providers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  width: $--container-width;
}

.game-provider {
  filter: grayscale(100%);
  padding: 0 50px;
  transition: filter 0.5s;

  img {
    height: 60px;
    width: 60px;
  }

  &:hover {
    filter: grayscale(0%);
  }
}
</style>
