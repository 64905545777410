<template>
  <el-container class="default-layout">
    <el-header class="default-layout__header" height="auto">
      <AppBar />
      <Navbar />
    </el-header>
    <router-view />
    <el-footer class="default-layout__footer" height="auto">
      <GameProviders />
      <SiteLinks />
      <Copyright />
    </el-footer>
  </el-container>
</template>

<script>
import AppBar from "@/components/Header/AppBar.vue";
import Copyright from "@/components/Footer/Copyright.vue";
import GameProviders from "@/components/Footer/GameProviders.vue";
import Navbar from "@/components/Header/Navbar.vue";
import SiteLinks from "@/components/Footer/SiteLinks.vue";

export default {
  components: {
    SiteLinks,
    AppBar,
    Copyright,
    GameProviders,
    Navbar,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variable";

.default-layout {
  &__header {
    padding: 0 !important;
  }

  &__footer {
    background: $footer-background;
    padding: 20px 0 !important;
  }
}
</style>
