<template>
  <div :class="$style.copyright">版權所有 © {{ site.name }} 保留所有權利</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Copyright",
  computed: {
    ...mapGetters({
      site: "setting/site",
    }),
  },
};
</script>

<style lang="scss" module>
@import "@/assets/scss/variable";

.copyright {
  color: $copyright-text;
  font-size: 16px;
  margin: auto;
  text-align: center;
  width: $--container-width;
}
</style>
