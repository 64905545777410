import axios from "axios";
import store from "@/store";
import router, { ROUTE_NAMES } from "@/router";
import { Loading } from "element-ui";

const loading = {
  count: 0,
  instance: null,
};

function showLoading() {
  loading.count++;

  if (loading.count === 1) {
    loading.instance = Loading.service({
      lock: true,
    });
  }
}

function hideLoading() {
  loading.count--;

  if (loading.count === 0) {
    loading.instance.close();
  }
}

export default function (axiosOptions, customOptions = {}) {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL || "",
  });

  customOptions = {
    showLoading: true,
    ...customOptions,
  };

  instance.interceptors.request.use((config) => {
    const isAuthenticated = store.getters["user/isAuthenticated"];

    if (isAuthenticated) {
      config.headers["Authorization"] = `Bearer ${store.getters["user/token"]}`;
    }

    if (customOptions.showLoading) {
      showLoading();
    }

    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      if (customOptions.showLoading) {
        hideLoading();
      }

      return response.data;
    },
    (error) => {
      if (customOptions.showLoading) {
        hideLoading();
      }

      if (error.response.status === 401) {
        store.commit("user/INITIAL_STATE");
        const { name, fullPath } = router.currentRoute;

        if (name !== ROUTE_NAMES.LOGIN) {
          return router.push({
            name: ROUTE_NAMES.LOGIN,
            query: { redirectTo: fullPath },
          });
        }
      }

      return Promise.reject(error);
    }
  );

  return instance(axiosOptions);
}
