<template>
  <div class="personal-layout">
    <div class="personal-layout__container">
      <div class="personal-layout__section personal-layout__section--left">
        <div class="personal-layout__profile">
          <img
            src="@/assets/images/avatar.png"
            class="personal-layout__avatar"
          />
          <div class="personal-layout__info">
            <div class="personal-layout__username">{{ user.username }}</div>
            <div class="personal-layout__join-days">
              加入{{ site.name }}第{{ user.registered_days }}天
            </div>
            <div class="personal-layout__balance">
              中心錢包：<span
                v-money-format="user.main_balance"
                class="personal-layout__balance-value"
              ></span>
            </div>
            <div class="personal-layout__actions">
              <router-link
                to="/wallet/recharge"
                class="personal-layout__action"
              >
                <img
                  src="@/assets/images/personal/recharge.png"
                  class="personal-layout__action-icon"
                />
                <span class="personal-layout__action-text">儲值</span>
              </router-link>
              <router-link
                to="/wallet/withdraw"
                class="personal-layout__action"
              >
                <img
                  src="@/assets/images/personal/withdraw.png"
                  class="personal-layout__action-icon"
                />
                <span class="personal-layout__action-text">託售</span>
              </router-link>
              <router-link
                to="/wallet/transfer"
                class="personal-layout__action"
              >
                <img
                  src="@/assets/images/personal/transfer.png"
                  class="personal-layout__action-icon"
                />
                <span class="personal-layout__action-text"> 轉點 </span>
              </router-link>
            </div>
          </div>
        </div>
        <el-menu
          :default-active="$route.path"
          :router="true"
          class="personal-layout__menu"
        >
          <template v-for="(item, index) in menu">
            <el-menu-item :key="index" :index="item.path">
              <i class="el-icon-menu"></i>
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </template>
        </el-menu>
        <el-button
          class="personal-layout__button personal-layout__button--logout"
          @click="logout"
        >
          登出帳號
        </el-button>
      </div>
      <div class="personal-layout__section personal-layout__section--right">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ROUTE_NAMES } from "@/router";
import AuthService from "@/service/AuthService";

export default {
  name: "PersonalLayout",
  data() {
    return {
      menu: [
        {
          name: "個人資料",
          path: "/personal/information",
        },
        {
          name: "銀行帳戶",
          path: "/personal/bank-account",
        },
        {
          name: "修改密碼",
          path: "/personal/change-password",
        },
        {
          name: "交易紀錄",
          path: "/personal/transaction-record",
        },
        {
          name: "投注記錄",
          path: "/personal/bet-record",
        },
        {
          name: "通知中心",
          path: "/personal/notification",
        },
      ],
      ROUTE_NAMES,
    };
  },
  computed: {
    ...mapGetters({
      site: "setting/site",
      user: "user/user",
    }),
  },
  methods: {
    logout() {
      this.$router.push({ name: ROUTE_NAMES.LOGIN });
      AuthService.getInstance().logout();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variable";

.personal-layout {
  background: url("@/assets/images/home/background.png") no-repeat center bottom;
  background-size: cover;
  min-height: 600px;

  &__container {
    align-items: flex-start;
    display: flex;
    gap: 30px;
    margin: 50px auto;
    width: $--container-width;
  }

  &__section {
    &--left {
      background: $--color-white;
      border-radius: 10px;
      box-shadow: 0 2px 8px 0 rgb(224 195 146 / 30%);
      padding: 22px 18px;
      width: 220px;
    }

    &--right {
      background: $--color-white;
      border-radius: 10px;
      box-shadow: 0 2px 8px 0 rgb(224 195 146 / 30%);
      flex: 1;
      padding: 22px 18px;
    }
  }

  &__profile {
    align-items: center;
    border-bottom: 1px solid $--border-color-base;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
  }

  &__avatar {
    border: 1px solid $--border-color-base;
    border-radius: 50%;
    height: 80px;
    width: 80px;
  }

  &__info {
    text-align: center;
  }

  &__username {
    color: #222;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    text-align: center;
  }

  &__join-days {
    color: #4d5663;
    font-size: 12px;
    margin-bottom: 12px;
    opacity: 0.6;
    text-align: center;
  }

  &__balance {
    color: #222;
    font-size: 14px;
    text-align: center;

    span {
      font-family: $--font-price-family;
      font-size: 16px;
      font-weight: bold;
    }
  }

  &__actions {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 20px;
  }

  &__action {
    align-items: center;
    display: flex;
    flex-direction: column;

    &-icon {
      height: 40px;
      transition: transform 0.5s ease;
      width: 40px;
    }

    &:hover &-icon {
      transform: scale(1.1);
    }

    &-text {
      color: #222;
      font-size: 12px;
      margin-top: 4px;
    }
  }

  &__menu.el-menu {
    border: none;
    margin-bottom: 60px;
    margin-top: 20px;

    .el-menu-item {
      align-items: center;
      display: flex;
      height: 40px;
      justify-content: start;
      line-height: 40px;
      margin: 4px 0 8px;
      padding: 0 10px !important;

      &:focus,
      &:hover,
      &:hover .el-icon-menu {
        background-color: transparent;
        color: $--color-primary;
      }

      &.is-active {
        background: $--color-primary;
        border-radius: 6px;
        color: $--color-white;

        &:hover .el-icon-menu {
          color: $--color-white;
        }
      }
    }
  }

  &__button {
    border-radius: 6px;
    width: 100%;

    &--logout {
      margin-top: auto;
    }
  }
}
</style>
