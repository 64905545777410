import axios from "@/plugins/axios";

export default {
  list() {
    return axios({
      url: "/activity-category/list",
      method: "GET",
    });
  },
};
