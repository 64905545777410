<template>
  <div class="auth-layout">
    <img class="auth-layout__brand" :src="site.logo" alt="" />
    <div class="auth-layout__container">
      <div class="auth-layout__tabs">
        <router-link class="auth-layout__tab" :to="{ name: ROUTE_NAMES.LOGIN }">
          登入
        </router-link>
        <router-link
          class="auth-layout__tab"
          :to="{ name: ROUTE_NAMES.REGISTER }"
        >
          註冊
        </router-link>
      </div>
      <div class="auth-layout__form">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { ROUTE_NAMES } from "@/router";
import { mapGetters } from "vuex";

export default {
  name: "AuthLayout",
  data() {
    return {
      ROUTE_NAMES,
    };
  },
  computed: {
    ...mapGetters({
      site: "setting/site",
    }),
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variable";

.auth-layout {
  align-items: center;
  background: url("@/assets/images/auth/background.png") no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  &__brand {
    margin-bottom: 36px;
    width: 300px;
  }

  &__container {
    background: $--color-white;
    border-radius: 20px;
    box-shadow: 0 0 20px 0 $--color-primary;
    width: 400px;
  }

  &__tabs {
    display: flex;
    justify-content: space-between;
  }

  &__tab {
    background: $--color-primary;
    color: $--color-white;
    flex: 1;
    font-size: 16px;
    padding: 14px 0;
    text-align: center;

    &:first-child {
      border-top-left-radius: 20px;
    }

    &:last-child {
      border-top-right-radius: 20px;
    }

    &.router-link-active {
      background: $--color-white;
      color: $--color-primary;
    }
  }

  &__form {
    padding: 20px;

    .el-form-item {
      margin-bottom: 20px;

      .el-form-item__label {
        line-height: initial;
        padding-bottom: 6px;
      }

      .el-input input {
        background-color: #faf5ed;
        border-color: $--color-primary;
        border-radius: 20px;

        &::placeholder {
          color: #cdaf80;
        }
      }
    }
  }
}
</style>
