import axios from "@/plugins/axios";

export default {
  list(params) {
    return axios({
      url: "/announcement/list",
      method: "GET",
      params,
    });
  },
  detail(id) {
    return axios({
      url: `/announcement/detail/${id}`,
      method: "GET",
    });
  },
};
