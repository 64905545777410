import api from "@/api";
import store from "@/store";

export default class AuthService {
  static getInstance() {
    return new AuthService();
  }

  async login(params) {
    const { data } = await api.auth.login(params);

    await store.commit("user/SET_TOKEN", data.token);
    await this.fetchProfile();
  }

  async register(params) {
    await api.auth.register({
      domain: window.location.hostname,
      ...params,
    });
  }

  async fetchProfile() {
    const { data } = await api.auth.profile();

    await store.commit("user/SET_USER", data);
  }

  async logout() {
    await store.commit("user/INITIAL_STATE");
  }

  updateProfile(params) {
    return api.auth.updateProfile(params);
  }

  forgotPassword(params) {
    return api.auth.forgotPassword(params);
  }

  async changePassword(params) {
    return await api.auth.changePassword(params);
  }

  async sendCaptcha(params) {
    return await api.common.sendCaptcha(params);
  }
}
