import api from "@/api";
import { Message } from "element-ui";
import store from "@/store";

export default class WalletService {
  static getInstance() {
    return new WalletService();
  }

  async getWalletList() {
    try {
      const response = await api.wallet.list();

      return response.data;
    } catch (error) {
      Message.error("獲取錢包列表失敗");
    }
  }
  async getPaymentChannels() {
    try {
      const response = await api.common.paymentChannels();

      return response.data;
    } catch (error) {
      Message.error("獲取支付通道失敗");
    }
  }

  recover() {
    return api.wallet.recover();
  }

  transfer(params) {
    return api.wallet.transfer(params);
  }

  recharge(params) {
    const user = store.getters["user/user"];

    if (!user.is_bind_bank_account) {
      Message.error("請先綁定銀行卡");

      return;
    }

    const token = store.getters["user/token"];
    const host = process.env.VUE_APP_API_URL;
    const path = `/wallet/recharge?token=${token}&amount=${params.amount}&payment_channel=${params.payment_channel}`;

    window.open(`${host}${path}`);
  }

  withdraw(params) {
    return api.wallet.withdraw(params);
  }
}
