<template>
  <el-dialog
    title="溫馨提示"
    class="login-game-dialog"
    width="480px"
    :visible.sync="dialogVisible"
    center
  >
    <div class="login-game-dialog__message">
      是否將中心錢包中的
      <span
        v-money-format.no-symbol="user.main_balance"
        class="login-game-dialog__balance"
      />
      元轉入 {{ game?.wallet_name }}？
    </div>
    <el-form label-width="80px">
      <el-form-item label="轉入金額">
        <el-input v-model="amount" />
      </el-form-item>
      <div class="login-game-dialog__actions">
        <el-button type="primary" plain round @click="transferToGame">
          立即轉入
        </el-button>
        <el-button type="primary" round @click="loginGame">
          無需轉入，立即遊戲
        </el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import GameService from "@/service/GameService";
import WalletService from "@/service/WalletService";
import TransferDirection from "@/enums/TransferDirection";

export default {
  name: "LoginGameDialog",
  data() {
    return {
      game: null,
      amount: null,
      dialogVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      isAuthenticated: "user/isAuthenticated",
    }),
  },
  methods: {
    open(game) {
      this.game = game;
      this.amount = null;
      this.dialogVisible = true;
    },
    close() {
      this.game = null;
      this.amount = null;
      this.dialogVisible = false;
    },
    async loginGame() {
      GameService.getInstance().launcherGame(this.game);

      this.close();
    },
    async transferToGame() {
      if (!this.amount) {
        this.$message.error("請輸入轉入金額");

        return;
      }

      try {
        await WalletService.getInstance().transfer({
          game_code: this.game.code,
          amount: this.amount,
          direction: TransferDirection.IN.value,
        });
        await this.$message.success("轉入成功");
      } catch (error) {
        this.$message.error("轉入失敗，請稍後再試");

        return;
      }

      await this.loginGame();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable";

.login-game-dialog {
  &__message {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
  }

  &__balance {
    font-family: $--font-price-family;
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
</style>
