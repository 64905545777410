import { ROUTE_NAMES } from "@/router";
import store from "@/store";

export default async (to, from, next) => {
  const requiresVerified = to.matched.some(
    (record) => record.meta.requiresVerified
  );
  const isVerified = store.getters["user/isVerified"];

  if (requiresVerified && !isVerified) {
    return next({
      name: ROUTE_NAMES.PERSONAL_INFORMATION,
    });
  }

  return next();
};
