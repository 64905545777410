import api from "@/api";
import store from "@/store";

export default class SettingService {
  static getInstance() {
    return new SettingService();
  }

  async fetchBootstrap() {
    try {
      const { data } = await api.common.bootstrap();

      store.commit("setting/SET_SITE", data.site);
    } catch (error) {
      store.commit("setting/SET_SITE", {
        maintenance_mode: true,
      });
    }
  }
}
