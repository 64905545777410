import store from "@/store";
import { ROUTE_NAMES } from "@/router";
import SettingService from "@/service/SettingService";

export default async (to, from, next) => {
  await SettingService.getInstance().fetchBootstrap();

  const maintenanceMode = store.getters["setting/maintenanceMode"];

  if (to.name === ROUTE_NAMES.MAINTENANCE && !maintenanceMode) {
    return next({ path: ROUTE_NAMES.HOME });
  }

  if (to.name !== ROUTE_NAMES.MAINTENANCE && maintenanceMode) {
    return next({ name: ROUTE_NAMES.MAINTENANCE });
  }

  return next();
};
