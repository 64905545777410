import axios from "@/plugins/axios";

export default {
  bet(params) {
    return axios({
      url: "/record/bet",
      method: "GET",
      params,
    });
  },
  transaction(params) {
    return axios({
      url: "/record/transaction",
      method: "GET",
      params,
    });
  },
  transactionDetail(id) {
    return axios({
      url: `/record/transaction/${id}`,
      method: "GET",
    });
  },
};
