<template>
  <div class="navbar">
    <div class="navbar__container">
      <router-link class="navbar__brand" to="/">
        <img :src="site.logo" />
      </router-link>
      <div class="navbar__items">
        <template v-for="(item, index) in gameMenu">
          <div
            :key="index"
            class="navbar__item"
            @mouseenter="showGameDropdown(index)"
            @mouseleave="hideGameDropdown"
          >
            <img :src="item.navigation_image.web.default" alt="" />
            <span>{{ item.name }}</span>
            <GameDropdown
              v-show="activeGameDropdownIndex === index"
              :items="item.games"
              class="navbar__dropdown"
              @login="loginGame"
            />
          </div>
        </template>

        <router-link class="navbar__item" :to="{ name: ROUTE_NAMES.ACTIVITY }">
          <img src="@/assets/images/navbar/activity.png" alt="" />
          <span>優惠</span>
        </router-link>

        <a class="navbar__item" :href="supportUrl" target="_blank">
          <img src="@/assets/images/navbar/support.png" alt="" />
          <span>客服</span>
        </a>
      </div>
    </div>
    <LoginGameDialog ref="loginGameDialog" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ROUTE_NAMES } from "@/router";
import GameService from "@/service/GameService";
import GameDropdown from "@/components/Header/GameDropdown.vue";
import LoginGameDialog from "@/components/Dialog/LoginGameDialog.vue";

export default {
  name: "Navbar",
  components: { LoginGameDialog, GameDropdown },
  data() {
    return {
      gameMenu: [],
      activeGameDropdownIndex: null,
      ROUTE_NAMES,
    };
  },
  computed: {
    ...mapGetters({
      site: "setting/site",
      supportUrl: "setting/supportUrl",
      isAuthenticated: "user/isAuthenticated",
    }),
  },
  mounted() {
    this.fetchGameMenu();
  },
  methods: {
    async fetchGameMenu() {
      this.gameMenu = await GameService.getInstance().getGameMenu();
    },
    loginGame(game) {
      if (game.is_multiple) {
        this.$router.push({
          name: ROUTE_NAMES.GAME_SLOT,
          query: {
            id: game.id,
          },
        });

        return;
      }

      if (!this.isAuthenticated) {
        this.$router.push({
          name: ROUTE_NAMES.LOGIN,
          query: {
            redirectTo: this.$route.fullPath,
          },
        });

        return;
      }

      this.$refs.loginGameDialog.open(game);
    },
    showGameDropdown(index) {
      this.activeGameDropdownIndex = index;
    },
    hideGameDropdown() {
      this.activeGameDropdownIndex = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable";

.navbar {
  background: $navbar-background;

  &__container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: $--container-width;
  }

  &__brand {
    img {
      height: 46px;
    }
  }

  &__items {
    align-items: center;
    display: flex;
    gap: 20px;
    justify-content: flex-end;
  }

  &__item {
    align-items: center;
    background: $navbar-item-background;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    transition: background 0.5s;

    img {
      height: 22px;
      margin-bottom: 4px;
    }

    span {
      color: $navbar-item-text;
      font-size: 14px;
    }

    &:hover,
    &.router-link-active {
      background: $navbar-item-background-active;
    }
  }
}
</style>
