import api from "@/api";
import store from "@/store";
import { Message } from "element-ui";

export default class GameService {
  static getInstance() {
    return new GameService();
  }

  async getGameMenu() {
    try {
      const response = await api.game.menu();

      return response.data;
    } catch (error) {
      Message.error("獲取遊戲菜單失敗");
    }
  }

  async getGameList(params) {
    try {
      const response = await api.game.list(params);

      return response.data;
    } catch (error) {
      Message.error("獲取遊戲列表失敗");
    }
  }

  async getGameLoginUrl(game) {
    const isAuthenticated = store.getters["user/isAuthenticated"];

    if (game.is_multiple) {
      return;
    }

    if (!game.is_enabled) {
      Message.error("遊戲未開放");

      return;
    }

    if (game.is_maintenance) {
      Message.error("遊戲維護中");

      return;
    }

    if (!isAuthenticated) {
      Message.error("請先登入會員");

      return;
    }

    try {
      const response = await api.game.login({
        game_id: game.id,
      });

      return response.data.url;
    } catch (error) {
      Message.error("遊戲登入失敗");
    }
  }

  launcherGame(game) {
    const isAuthenticated = store.getters["user/isAuthenticated"];

    if (game.is_multiple) {
      return;
    }

    if (!game.is_enabled) {
      Message.error("遊戲未開放");

      return;
    }

    if (game.is_maintenance) {
      Message.error("遊戲維護中");

      return;
    }

    if (!isAuthenticated) {
      Message.error("請先登入會員");

      return;
    }

    const token = store.getters["user/token"];
    const host = process.env.VUE_APP_API_URL;
    const path = `/game/launcher?game_id=${game.id}&token=${token}`;

    window.open(`${host}${path}`);
  }

  async getParentGameList(params) {
    return await this.getGameList({
      parent_id: "null",
      ...params,
    });
  }

  async getChildrenGameList(gameId, name = "") {
    return await this.getGameList({
      parent_id: gameId,
      name: name,
      search_fields: "name:like",
    });
  }
}
