<template>
  <div :class="$style['site-links']">
    <template v-for="(link, index) in links">
      <router-link :key="index" :class="$style['site-link']" :to="link.path">
        {{ link.name }}
      </router-link>
    </template>
    <a :class="$style['site-link']" :href="supportUrl" target="_blank">
      在線客服
    </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SiteLinks",
  computed: {
    ...mapGetters({
      supportUrl: "setting/supportUrl",
    }),
    links() {
      return [
        {
          name: "關於我們",
          path: "/about",
        },
        {
          name: "隱私政策",
          path: "/privacy",
        },
        {
          name: "使用條款",
          path: "/terms",
        },
        {
          name: "常見問題",
          path: "/faq",
        },
      ];
    },
  },
};
</script>

<style lang="scss" module>
@import "@/assets/scss/variable";

.site-links {
  border-top: 1px solid $site-link-divider;
  display: flex;
  justify-content: center;
  margin: 12px auto 0;
  padding: 24px 0;
  width: $--container-width;
}

.site-link {
  color: $site-link-text;
  display: block;
  font-size: 16px;
  padding: 0 30px;
  position: relative;

  &:not(:last-child)::after {
    background-color: $site-link-divider;
    bottom: 20%;
    content: "";
    position: absolute;
    right: 0;
    top: 20%;
    width: 1px;
  }
}
</style>
