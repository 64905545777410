import Vue from "vue";
import Vuex from "vuex";
import VuexPersistedstate from "vuex-persistedstate";
import user from "./modules/user";
import setting from "@/store/modules/setting";
import cookieStorage from "@/plugins/cookie-storage";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { user, setting },
  plugins: [
    new VuexPersistedstate({
      paths: ["user"],
      storage: cookieStorage,
    }),
  ],
});
