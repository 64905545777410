import axios from "@/plugins/axios";

export default {
  list(params) {
    return axios({
      url: "/bank-account/list",
      method: "GET",
      params,
    });
  },
  create(data) {
    return axios({
      url: "/bank-account/create",
      method: "POST",
      data,
    });
  },
  update(id, data) {
    return axios({
      url: `/bank-account/update/${id}`,
      method: "PUT",
      data,
    });
  },
  detail(id) {
    return axios({
      url: `/bank-account/detail/${id}`,
      method: "GET",
    });
  },
};
