export default class Enum {
  constructor(text, value) {
    this.text = text;
    this.value = value;
  }

  static values() {
    return Object.values(this);
  }

  static fromValue(value) {
    return this.values().find((item) => item.value === value);
  }

  static toSelectArray() {
    return this.values().map((item) => {
      return {
        text: item.text,
        value: item.value,
      };
    });
  }
}
