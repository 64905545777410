import activity from "@/api/modules/activity";
import activityCategory from "@/api/modules/activity-category";
import announcement from "@/api/modules/announcement";
import auth from "./modules/auth";
import bank from "@/api/modules/bank";
import bankAccount from "@/api/modules/bank-account";
import common from "./modules/common";
import file from "@/api/modules/file";
import game from "./modules/game";
import record from "./modules/record";
import wallet from "./modules/wallet";

export default {
  activity,
  activityCategory,
  announcement,
  auth,
  bank,
  bankAccount,
  common,
  file,
  game,
  record,
  wallet,
};
