const initialState = () => ({
  user: {
    id: null,
    avatar: "",
    realname: null,
    birthday: null,
    username: "",
    main_balance: 0,
    registered_at: "",
    registered_days: 1,
    is_bind_bank_account: false,
    is_bind_crypto_account: false,
  },
  token: null,
});

export default {
  namespaced: true,
  state: initialState,
  getters: {
    user: (state) => state.user,
    token: (state) => state.token,
    isVerified: (state) => state.user.realname && state.user.birthday,
    isAuthenticated: (state) => !!state.token,
    isBindBankAccount: (state) => state.user.is_bind_bank_account,
    isBindCryptoAccount: (state) => state.user.is_bind_crypto_account,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    INITIAL_STATE(state) {
      state.user = initialState().user;
      state.token = initialState().token;
    },
  },
};
