import axios from "@/plugins/axios";

export default {
  login(data) {
    return axios({
      url: "/auth/login",
      method: "POST",
      data,
    });
  },
  profile() {
    return axios(
      {
        url: "/auth/profile",
      },
      { showLoading: false }
    );
  },
  register(data) {
    return axios({
      url: "/auth/register",
      method: "POST",
      data,
    });
  },
  updateProfile(data) {
    return axios({
      url: "/auth/update-profile",
      method: "PUT",
      data,
    });
  },
  checkUsername(data) {
    return axios({
      url: "/auth/check-username",
      method: "POST",
      data,
    });
  },
  forgotPassword(data) {
    return axios({
      url: "/auth/forgot-password",
      method: "POST",
      data,
    });
  },
  changePassword(data) {
    return axios({
      url: "/auth/change-password",
      method: "POST",
      data,
    });
  },
};
